<template>
  <div id="formulario-edit-info">   
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <vx-card>
        <div class="vx-row mb-4">
            <div class="vx-col w-1/2 items-center">                    
                <vs-button class="ml-auto mt-2" size="small" @click="gravar" color="success">Gravar</vs-button>
                <vs-button class="ml-4 mt-2" size="small" type="border" color="dark" @click="voltar">Voltar</vs-button>                             
            </div>
            <div class="vx-col w-1/2 flex items-center">                    
                <p class="ml-auto text-xs font-medium" v-if="data_local.id">Último Acesso: {{data_local.dataHoraUltimoAcesso}}</p>  
                <h6 class="ml-auto font-medium" :class="{'text-success':!data_local.exclusaoLogica, 'text-danger': data_local.exclusaoLogica }" v-if="data_local.id"> {{( data_local.exclusaoLogica ? 'INATIVO': 'ATIVO')}}</h6>                  
                <vs-button class="ml-4" size="small" @click="excluir" v-show="isDeletable" color="primary" type="filled">{{labelInativarAtivar}}</vs-button>                            
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-1/3"><label class="vs-input--label">Empresa</label></div>     
            <div class="vx-col w-1/3"><label class="vs-input--label">Nome</label></div>     
            <div class="vx-col w-1/3"><label class="vs-input--label">Login</label></div>     
               
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/3 flex">
                <v-select  
                    label="nome"      
                    :options="listaEmpresas"  
                    placeholder="Selecione a central"
                    :reduce="c => c.id"                            
                    @input="fetchListaClientes"                          
                    v-model="data_local.idEmpresa"                          
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        {{ option.nome }}
                    </template>
                </v-select>  
            </div>
            <div class="vx-col w-1/3 flex">
                <vs-input class="w-full" v-model="data_local.nome" :maxlength="255"/>   
            </div>     
            <div class="vx-col w-1/3 flex">                
                <vs-input class="w-full" v-model="data_local.login" :maxlength="255"/>   
            </div>  
            
        </div>  
         <div class="vx-row">
             <div class="vx-col w-1/3"><label class="vs-input--label">Senha</label></div>  
            <div class="vx-col w-1/3"><label class="vs-input--label">Perfil</label></div>     
            <div class="vx-col w-1/3" v-if="data_local.perfil === 'TECNICO'"><label class="vs-input--label">Técnico</label></div>     
            <div class="vx-col w-1/3" v-if="data_local.perfil === 'MONITOR_PANICO' || data_local.perfil === 'EMPRESA_MONITOR_PANICO'"><label class="vs-input--label">Cliente Monitorado</label></div>     
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/3 flex">
                <vs-input class="w-full" type="password" v-model="data_local.senha" :maxlength="255"/>   
            </div>     
            <div class="vx-col w-1/3">
                <v-select                                               
                    :options="listaPerfis" 
                    v-model="data_local.perfil"      
                    :reduce="r => r.data"                
                    class="w-full vs-input--label">
                 </v-select> 
            </div>   
            <div class="vx-col w-1/3" v-if="data_local.perfil === 'TECNICO'">
                <v-select       
                    label="nome"               
                    :options="listaTecnicos"  
                    :reduce="t => t.id"  
                    placeholder="Selecione o técnico"
                    v-model="data_local.idTecnico"                          
                    class="style-chooser w-full vs-input--label">                    
                </v-select>  
            </div>
            <div class="vx-col w-1/3" v-if="data_local.perfil === 'MONITOR_PANICO' || data_local.perfil === 'EMPRESA_MONITOR_PANICO'">
                <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    placeholder="Selecione o cliente"
                    :reduce="c => c.id"
                    :filter="pesquisarCliente"
                    v-model="data_local.idClienteMonitorado"                          
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        {{ option.nome }}
                    </template>
                </v-select>  
            </div>
        </div>  
        <div class="vx-row mt-2">            
            <div class="vx-col w-1/3">
                <label class="vs-input--label">Email</label>
                <vs-input class="w-full" v-model="data_local.email" :maxlength="255"/>   
            </div>                                     
            <div class="vx-col w-1/3 flex">        
                <vs-input class="w-full" label="Controle de Acesso por IP" v-model="ipPermitidoSelecionado" name="ipPermitidoSelecionado" />                                        
                <vs-button class="ml-2 mt-6" size="small" @click="adicionarIP" :disabled="!ipPermitidoSelecionado" color="success">+</vs-button>  
            </div>  
        </div>
        <div class="vx-row mt-4">
            <div class="vx-col w-1/3 flex">
                
            </div> 
            <div class="vx-col w-1/3 flex">        
                <vs-table :hoverFlat="true" noDataText="..." :data="listaIpPermitidos" class="w-full" >
                    <template slot="thead">
                        <vs-th></vs-th>
                        <vs-th>IP PERMITIDO</vs-th>                                
                    </template>
                    <template slot-scope="{data}">
                        <tbody>
                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td>
                                    <vs-button size="small" @click="removerIP(indextr)" color="danger">-</vs-button>  
                                </vs-td>
                                <vs-td><p>{{ tr.ipPermitido }}</p></vs-td>                                        
                            </vs-tr>
                        </tbody>
                    </template>
                </vs-table>
            </div>
               
        </div>
    </vx-card>  
  </div>
</template>

<script>
import axios from "@/axios.js"
import { CONSTANTE_PERFIL_USUARIO } from '@/constantesComboBox.js'
import { CONSTANTE_PERFIL_USUARIO_PERFIL_SUPERVISOR } from '@/constantesComboBox.js'
import { CONSTANTE_PERFIL_USUARIO_CENTRAL } from '@/constantesComboBox.js'

const URL_SERVLET = "/UsuariosRest";
const NAME_ROUTE_SEARCH = "cadastro-usuarios-pesquisar";

export default {     
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
            return {nome: null, login: null, senha:null, perfil: null, telefone: null, observacao: null, email: null}
       }
    },
  },
  data() {
    return {        
        errorsValidacao: [],  
        data_local: JSON.parse(JSON.stringify(this.registroOriginal)),  
        listaTecnicos: [], 
        listaClientes: [],        
        listaEmpresas: [],
        listaPerfis : [],
        listaIpPermitidos: [],
        ipPermitidoSelecionado: null,

    }
  },  
  created(){
  }, 
  mounted() {
    if (this.data_local != null && this.data_local.hasOwnProperty("id")) {
        this.fetchIPs();
    }
    this.fetchListaEmpresas();
    this.fetchListaTecnicos(); 
    this.fetchListaClientes();
    if (this.isAcessoConcept) {
        if (this.isAcessoAdministradorConcept) {
            this.listaPerfis = CONSTANTE_PERFIL_USUARIO;
        } else {
            this.listaPerfis = CONSTANTE_PERFIL_USUARIO_PERFIL_SUPERVISOR;
        }        
    } else {
        this.listaPerfis = CONSTANTE_PERFIL_USUARIO_CENTRAL;
    }    
  },
  computed: {        
    labelInativarAtivar() {
        return ( this.data_local && this.data_local.exclusaoLogica ? 'ATIVAR' : 'INATIVAR');
    },
    isDeletable() {
       return this.data_local != null && this.data_local.hasOwnProperty("id");
    },    
    isAcessoConcept() {
        return this.$store.state.AppActiveUser.idEmpresa === 1;
    },
    isAcessoAdministradorConcept() {
        return this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR';
    }
  },
  methods: {  
    validateIPaddress(ipaddress) {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[*])\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[*])\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[*])$/.test(ipaddress))
        {
            return true;
        }
        
        return false;
    },
    adicionarIP() {   
        if (!this.validateIPaddress(this.ipPermitidoSelecionado)) {
            this.$vs.dialog({color:'danger', title: 'Erro',  text: "IP inválido", acceptText: 'Fechar' })
			return;
        }

        for (var i=0; i < this.listaIpPermitidos.length; i++) {
          if (this.listaIpPermitidos[i].ipPermitido === this.ipPermitidoSelecionado) {
            this.$vs.dialog({color:'danger', title: 'Erro',  text: "IP já cadastrado", acceptText: 'Fechar' })
			return;
          }
        }
        this.listaIpPermitidos.push({ipPermitido: this.ipPermitidoSelecionado});
        this.ipPermitidoSelecionado = null;
    },  
    removerIP(indice) {
      this.listaIpPermitidos.splice(indice,1);
    },
    fetchIPs() {    
        const params = new URLSearchParams();
        params.append('method', 'LISTAR_IPS_PERMITIDOS');
        params.append('outputFormat', 'JSON');
        params.append('id', this.data_local.id); 
        params.append('idEmpresa', this.data_local.idEmpresa);   
        
        axios.post(URL_SERVLET, params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/login' });
            } else {
                this.listaIpPermitidos = response.data;         
            }
        })
        .catch((error) => { 
            /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - fetchLista IP Permitidos - " + error); 
        })          
    },
    pesquisarCliente(options, varTermoPesquisa) {
        varTermoPesquisa = varTermoPesquisa.toUpperCase();
        return options.filter(function (c) {
                return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
        })
    }, 
    excluir() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja ' + this.labelInativarAtivar +' ?', acceptText: 'Sim', cancelText: 'Não', accept: this.excluirRequest
        });
    },
    excluirRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('method', 'Delete');      
        params.append('id', this.data_local.id);   
        params.append('idEmpresa', this.data_local.idEmpresa);   
        params.append('exclusaoLogica', !this.data_local.exclusaoLogica);   
                
        axios.post(URL_SERVLET, params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },
    gravar() {        
        if (this.formularioValido()) { 
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            
            if (this.registroOriginal.hasOwnProperty("id")) {
                params.append('method', 'Update');      
                params.append('id', this.data_local.id);      
            }  else {
                params.append('method', 'Insert');      
            }  

            params.append('nome', this.data_local.nome);      
            params.append('login', this.data_local.login);      
            params.append('email', this.data_local.email);      
            params.append('senha', this.data_local.senha);      
            params.append('perfil', this.data_local.perfil);   
            params.append('idEmpresa', this.data_local.idEmpresa);   
            params.append('exclusaoLogica', this.data_local.exclusaoLogica);  

            if (this.data_local.perfil === 'TECNICO') {
                params.append('idTecnico', this.data_local.idTecnico);   
            }   

            if (this.data_local.idClienteMonitorado) {
                params.append('idClienteMonitorado', this.data_local.idClienteMonitorado);   
            }   

            if (this.listaIpPermitidos && this.listaIpPermitidos.length > 0) {
                for(var i = 0; i < this.listaIpPermitidos.length; i++) {
                    params.append("ipPermitidos", JSON.stringify(this.listaIpPermitidos[i]));
                }                    
            }
            
            axios.post(URL_SERVLET, params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    /* eslint-disable no-console */ console.log(response.data);
                    if (response.data.hasOwnProperty("error")) {
                            this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                    } else {
                        this.$router.push({ name: NAME_ROUTE_SEARCH })
                    }                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        }
         
    },
    voltar() {
        this.$router.push({
            name: NAME_ROUTE_SEARCH                     
        })
    },
    formularioValido() {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];
      
        if (!this.data_local.nome) {
            this.errorsValidacao.push("Nome obrigatório");
        }

        if (!this.data_local.login) {
            this.errorsValidacao.push("Login obrigatório");
        }

        if (!this.data_local.senha) {
            this.errorsValidacao.push("Senha obrigatório");
        }

        if (!this.data_local.perfil) {
            this.errorsValidacao.push("Perfil obrigatório");
        }

        if (this.data_local.perfil === 'TECNICO') {
            if (!this.data_local.idTecnico) {
                this.errorsValidacao.push("Selecione o técnico");
            }
        }

        if (!this.errorsValidacao.length) {
            return true;
        }   
        return false;
    },
    fetchListaTecnicos() {     
        this.$vs.loading() 
        const params = new URLSearchParams();
        params.append('method', 'FindAll');
        params.append('orderDirection', 'ASC');
        params.append('outputFormat', 'JSON');
        
        axios.post("/TecnicoRest", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.listaTecnicos = response.data;                            
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
        })    
                            
    },
    fetchListaClientes() {     
        const params = new URLSearchParams();
        params.append('method', 'FindAll');
        params.append('orderDirection', 'ASC');
        params.append('outputFormat', 'JSON');

        if (this.data_local.idEmpresa && this.data_local.idEmpresa) {
            params.append('idCentral', this.data_local.idEmpresa);
        }
        
        this.$vs.loading() 
        axios.post("/ClientesRest", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.listaClientes = response.data;                            
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
        })    
                            
    }, 
    fetchListaEmpresas() {     
        this.$vs.loading() 
        const params = new URLSearchParams();
        params.append('outputFormat', 'JSON');
        
        axios.post("/ListarEmpresas", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.listaEmpresas = response.data;                            
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
        })    
                            
    },
  }, 
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 300px);
      position: relative;      
    } 
</style>